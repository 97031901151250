// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$extranet-primary: mat.define-palette(mat.$indigo-palette);
$extranet-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$extranet-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$extranet-theme: mat.define-light-theme((color: (primary: $extranet-primary,
        accent: $extranet-accent,
        warn: $extranet-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($extranet-theme);

/* You can add global styles to this file, and also import other style files */
@import "assets/styles/constants";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: 'New-Calibri';
  src: url('assets/fonts/calibri.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  color: $main-font-color;
  margin: 0;
  font: 400 14px Arial;
  box-sizing: border-box;
  &.overflow-h {
    overflow-y: hidden;
  }
}

div,
label,
input,
select,
button {
  box-sizing: border-box;
}

label {
  font-size: 12px;
}

input[type="checkbox"],
input[type="radio"],
select {
  cursor: pointer;
}

button {
  font-size: 14px;
  font-weight: 600;
  border: none;
  padding: 7px 15px;
  // border-radius: 7px;
  border-bottom-left-radius: 50px 200px;
  border-top-right-radius: 50px 200px;
  cursor: pointer;
  background: #e7e6e6;
}

button.icon {
  padding: 7px 9px;
  border-radius: 50%;
}

button:hover,
button:active {
  background: #cccccc;
}

//button.warning {}
button.primary {
  color: white;
  background: $main-color;
}

button.accept {
  color: white;
  background: #42ad45;
}

button.primary:hover,
button.primary:active {
  background: $main-color-dark;
}

button.accept:hover,
button.accept:active {
  background: #225F26;
}

button[disabled] {
  opacity: 0.4;
  cursor: initial;
}

button.buttonIcon {
  border-radius: 50%;
  font-size: 16px;
  line-height: 0.9;
  padding: 10px 12px 10px 12px;
}

input[type='checkbox'] {
  border-color: $border-color;
  top: 2px;
  position: relative;
}

input[type='radio'] {
  border-color: $border-color;
  top: 2px;
  position: relative;
}

.content {
  padding: 20px;
}

.contentCustom {
  padding: 24px;
}

.f-grow {
  flex: 1
}

// .d-flex {
//   display: flex !important;
// }

.d-inline {
  display: inline-block !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-gap-2 {
  grid-gap: 2em !important;
}

.grid,
.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6,
.grid-7 {
  display: grid;
  grid-gap: 0.5em 2em;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid-7 {
  grid-template-columns: repeat(7, 1fr);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.height-100 {
  height: 100%;
}

.topicHeader {
  width: 100%;
  color: $main-font-color;
  font-size: 18px;
  margin-top: 2px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid $main-color;
}

.fieldList .inputField {
  margin-bottom: 2em;
}

.inputField {
  min-width: 100%;
  width: 100%;
  display: flex;
  height: 43px;
  position: relative;

  label {
    display: block;
    font-size: 12px;
    position: absolute;
    top: -5px;
  }

  input,
  select {
    min-width: 100%;
    flex-grow: 1;
    margin-top: 15px;
    display: block;
    padding: 9px 10px;
    border: 1px solid $border-color;
    height: 40px;
  }

  input:focus,
  input:active,
  select:focus,
  select:active {
    outline: none;
    border-color: $focus-color;
  }

  input:focus+label,
  input:active+label,
  select:focus+label,
  select:active+label {
    color: $focus-color;
  }

  &.lang {
    input {
      padding-left: 42px;

      +label::before {
        content: "";
        height: 40px;
        width: 33px;
        position: absolute;
        border: 1px solid $border-color;
        border-right: 2px solid $border-color;
        top: 20px;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
      }
    }

    input.ng-invalid+label::before {
      border-color: $error-color;
    }

    input:focus+label::before,
    input.ng-invalid:focus+label::before {
      border-color: $focus-color;
    }

    &.lang-uk input+label::before {
      background-image: url("./assets/images/ua.svg");
    }

    &.lang-uk textarea+label::before {
      background-image: url("./assets/images/ua.svg");
    }

    &.lang-uk textarea {
      margin-top: 15px;
      font-size: 12px;
      height: 44px;
    }

    &.lang-en textarea {
      margin-top: 15px;
      font-size: 12px;
      height: 44px;
      resize: none;
    }

    &.lang-en input+label::before {
      background-image: url("./assets/images/gb.svg");
    }
  }
}

textarea,
input.inputField,
select.inputField {
  height: auto;
  width: max-content;
  display: inline-block;
  padding: 9px 8px;
  border: 1px solid $border-color;
}

textarea:focus,
textarea:active,
input.inputField:focus,
select.inputField:focus,
input.inputField:active,
select.inputField:active {
  outline: none;
  border-color: $focus-color;
}

.inputField.shortField {
  min-width: 50px;
  height: 23px;
  text-align: center;
  margin-bottom: 0;
  width: 50px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input.ng-invalid,
select.ng-invalid,
input.ng-invalid+label,
select.ng-invalid+label,
textarea.ng-invalid {
  color: $error-color;
  border-color: $error-color;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pb-3 {
  padding-bottom: 30px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pt-3 {
  padding-top: 30px !important;
}

.savePanel {
  text-align: right;
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 400px;

  button {
    padding: 10px 20px;
  }
}

table.matTable {
  color: rgba(0, 0, 0, .87);
  box-shadow: $box-shadow;
  border-spacing: 0;
  width: 100%;

  thead tr {
    height: 56px;
  }

  tbody tr {
    height: 48px;
  }

  th,
  td {
    border-bottom-color: rgba(0, 0, 0, .12);
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  }

  td {
    font-size: 14px;
  }

  th:first-child,
  td:first-child {
    padding-left: 24px;
  }

  th:last-child,
  td:last-child {
    padding-right: 24px;
  }
}

.imageBox {
  overflow: hidden;
  position: relative;
  height: 180px;
  width: 100%;

  img, video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.empty-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 16px;
    font-weight: bold;
    color: #ddd;
  }
}

#notification {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  height: 50px;
  width: 250px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  color: white;
  animation-duration: 2s;
  animation-name: slidein;
}

#notification.success {
  background: linear-gradient(to right, #417142 50%, #81c784 50%) right;
}

#notification.fail {
  background: linear-gradient(to right, #8e3729 50%, #f85d42 50%) right;
}

#notification {
  background-size: 200% 100% !important;
}

@keyframes slidein {
  from {
    background-position: right;
  }

  to {
    background-position: left;
  }
}

agm-map {
  height: 300px;
}

.close {
  z-index: 2;
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 15px !important;
  width: 35px;
  height: 35px;
  padding: 7px 9px 8px 9px !important;
  border-radius: 50% !important;
}

.modal-body {
  padding: 20px;
}

.multiselect-dropdown {
  .dropdown-btn {
    padding: 9px 10px;
    border: 1px solid $border-color;
    height: 40px;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.overflow-y-auto {
  overflow-y: auto;
}

//!!! .nb-theme-default nb-layout .layout .layout-container .content .columns nb-layout-column {
//   padding: 0;
// }

.tooltip {
  position: absolute;
  white-space: pre-wrap;
}

@media only screen and (max-width: 1200px) {
  #notification {
    height: 35px;
    width: 200px;
    font-size: 12px;
  }
}
